<template>
	<div class="k-page-wrapper">
		<h2 class="k-page-title">
			<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
			Site Issue Reports
		</h2>

		<div style="clear:both">
			<div class="py-4 d-flex">
				<v-spacer />
				<v-text-field v-model="search" prepend-inner-icon="fa fa-search" clearable
					clear-icon="fa fa-times-circle" label="Search" single-line hide-details outlined dense
					background-color="#fff" style="flex:0 1 450px"></v-text-field>
				<v-spacer />
			</div>

			<v-data-table v-if="update_rows > 0" light dense :headers="headers" :items="rows" :search="search"
				:footer-props="footer_options" :items-per-page="10" :sort-by="['status_val', 'site_issue_id']"
				:sort-desc="[false, true]" class="k-site-issues-table">
				<template v-slot:item="{ item }">
					<!-- MC: Would be nice to be able to append the site_issue_id to the link to scroll to here, but currently doesn't work (eg. http://localhost:7048/admin/site_issue_reports#9) for the 9th issue -->
					<tr :id=item.site_issue_id>
						<td class="text-center pt-3">{{ item.site_issue_id }}</td>
						<td class="text-center pt-3">{{ item.created_at }}</td>
						<td class="text-left pt-3"><a :href="'mailto:' + item.creator_email"
								style="font-weight:bold;">{{ item.creator_name }}</a></td>
						<td class="text-left">
							<div style="width:150px" class="mx-0 mt-2"><v-select v-model="item.raw_issue_row.status"
									@change="save_issue_update(item.site_issue_id, 'status', item.raw_issue_row.status, item.status)"
									:items="statuses" label="Status"
									:background-color="((item.status == 'submitted') ? 'red lighten-2' : ((item.status == 'in_process') ? 'amber' : 'green lighten-2'))"
									dense solo hide-details></v-select></div>
							<div v-if="item.notes || editing_notes == item.site_issue_id" class="mt-2 mb-1"
								style="font-size:12px"><b>Admin notes:</b></div>
							<div v-if="item.notes && (!(editing_notes == item.site_issue_id))"
								class="k-site-issues-table-notes" v-html="item.notes.replace(/\n/g, '<br>')"></div>
							<v-btn v-if="!editing_notes" x-small color="#ddd" class="my-2"
								@click="editing_notes = item.site_issue_id; edited_note_original_val = item.notes">{{
									item.notes ? 'Edit' : 'Add' }}
								Notes</v-btn>
							<div v-if="editing_notes == item.site_issue_id" style="width:360px">
								<v-textarea ref="text_input" solo outlined hide-details clearable auto-grow label=""
									placeholder="Enter notes" v-model="item.raw_issue_row.notes" rows="2"></v-textarea>
								<v-btn x-small color="primary" class="my-2"
									@click="save_issue_update(item.site_issue_id, 'notes', item.raw_issue_row.notes, edited_note_original_val)">Save
									Notes</v-btn>
							</div>
						</td>
						<td class="text-left">
							<div v-html="item.description_html"></div>
							<img v-if="item.screenshot" :src="item.screenshot" class="float-left elevation-2"
								style="max-width:240px; max-height:240px; cursor:pointer; margin:8px; padding:4px; border-radius:4px;"
								@click="open_screenshot(item.screenshot)">
						</td>
						<td v-show="false">{{ item.notes }}</td>
					</tr>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {},
	props: {
	},
	data() {
		return {
			dialog_open: true,
			statuses: [
				{ value: "submitted", text: "Submitted" },
				{ value: "in_process", text: "In Process" },
				{ value: "resolved", text: "Resolved" },
			],
			headers: [
				{ text: 'ID', align: 'center', sortable: true, value: 'site_issue_id' },
				{ text: 'Date Reported', align: 'center', sortable: true, value: 'created_at' },
				{ text: 'Reporter', align: 'left', sortable: true, value: 'creator_name' },
				{ text: 'Status / Notes', align: 'left', sortable: true, value: 'status_val' },
				{ text: 'Asset / Issue Description', align: 'left', sortable: false, value: 'description_val' },
				// 'Notes' a hidden (via SCSS 'th:last-child) column just to allow for searchable notes
				{ text: 'Notes', align: 'left', sortable: false, value: 'notes' },
			],
			footer_options: {
				itemsPerPageOptions: [10, 50, 100, -1],
			},
			search: '',
			raw_issue_rows: [],
			editing_notes: false,	// set to a site_issue_id to edit notes for an issue
			edited_note_original_val: '',
			update_rows: 1,
		}
	},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		rows() {
			if (this.update_rows < 0) return
			let arr = []
			let tzo = new Date().getTimezoneOffset() * 60 * 1000	// dates come in GMT; use this to convert to local
			for (let row of this.raw_issue_rows) {
				let o = {}
				o.raw_issue_row = row

				o.site_issue_id = row.site_issue_id
				o.creator_email = row.creator_email
				o.creator_name = row.creator_name
				o.status = row.status
				o.notes = row.notes
				// o.description = row.description
				o.screenshot = row.screenshot

				// format date for local timezone, starting with GMT
				let d = date.parse(row.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo
				o.created_at = date.format(new Date(d), 'YYYY-MM-DD h:mm A')

				// sortable/searchable value for status
				if (o.status == 'submitted') o.status_val = '1_submitted'
				else if (o.status == 'in_process') o.status_val = '2_in_process'
				else if (o.status == 'resolved') o.status_val = '3_resolved'

				// build description_html
				o.description_html = ''
				if (row.data) {
					let type = (row.data.professional_development == "0") ? row.data.type : 'PD'

					// for links, keep in synch with what we have in ResourceLink.vue
					let link = ''
					if (row.data.type == 'collection_item' || row.data.type == 'lti') {
						// link = "LTI LINK"
					} else if (row.data.type == 'assessment') {
						// link = "ASSESSMENT"
					} else {
						let url = row.data.url ? row.data.url : 'URL'
						if (row.data.type == 'upload' || row.data.type == 'html') url = '/user-files/' + url
						link = sr('<a style="font-size:12px;word-break:break-all;" href="$1" target="_blank">$1</a>', url)
					}
					if (row.data.description && type && link) o.description_html += sr('<li><b>Resource:</b> $1 [$2] $3</li>', row.data.description, type, link)
				}
				if (row.lp_data) {
					// if we also have a unit, make the unit the link; otherwise make this a link
					if (row.lp_unit_data && row.lp_data.course_code && row.lp_data.title) {
						o.description_html += sr('<li><b>LP:</b> $2 [$1]</li>', row.lp_data.course_code, row.lp_data.title)
					} else if (row.lp_data.course_code && row.lp_data.title) {
						o.description_html += sr('<li><b>LP:</b> <a href="/collection/$1" target="_blank">$2</a> [$1]</li>', row.lp_data.course_code, row.lp_data.title)
					}
				}
				if (row.lp_unit_data) {
					// if we also have a lp, make this a link; otherwise plain text
					if (row.lp_data && row.lp_unit_data.lp_unit_id && row.lp_unit_data.title) {
						o.description_html += sr('<li><b>$2:</b> <a href="/collection/$4/$1" target="_blank">$3</a> [$1]</li>', row.lp_unit_data.lp_unit_id, row.lp_unit_data.title, row.lp_unit_data.description ? row.lp_unit_data.description : 'Link', row.lp_data.course_code)
					} else if (row.lp_unit_data.lp_unit_id, row.lp_unit_data.title, row.lp_unit_data.description) {
						o.description_html += sr('<li><b>$2:</b> $3 [$1]</li>', row.lp_unit_data.lp_unit_id, row.lp_unit_data.title, row.lp_unit_data.description)
					}
				}
				if (o.description_html) o.description_html = sr('<ul>$1</ul>', o.description_html)

				// add description, which will always be there
				o.description_html += sr('<div class="mt-2 mb-1" style="font-size:12px"><b>Issue description:</b></div><div class="k-site-issues-table-notes mb-2">$1</div>', row.description)

				// note: screenshot will be added in template (above)

				// store searchable version of description_html
				o.description_val = U.html_to_text(o.description_html)

				arr.push(o)
			}

			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
		this.get_report_data()
	},
	methods: {
		get_report_data() {
			let payload = {
				user_id: this.user_info.user_id,
			}

			U.loading_start()
			U.ajax('admin_get_site_issues', payload, result => {
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin_get_site_issues ajax call'); vapp.ping(); return;
				}
				console.log('raw row count: ' + result.issues.length)
				this.raw_issue_rows = result.issues
			});
		},

		open_screenshot(screenshot) {
			let blob = U.data_url_to_blob(screenshot)
			let blob_url = URL.createObjectURL(blob)
			window.open(blob_url)
		},

		save_issue_update(site_issue_id, key, val, old_val) {
			if (val != old_val) {
				// this kills the table for a tick, so that the refresh works properly after we save
				this.update_rows = 0

				console.log('save ' + key + ' for ' + site_issue_id + ': ' + val)
				// submit to service
				let payload = {
					site_issue_id: site_issue_id,
					user_id: this.user_info.user_id,
				}
				payload[key] = val

				U.loading_start()
				U.ajax('save_site_issue', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error updating issue.')
						return
					}
					this.$inform('Issue updated')

					// force the table to refresh
					setTimeout(x => ++this.update_rows, 0)
				});
			}

			// close notes editor if we just saved notes
			if (key == 'notes') {
				this.editing_notes = null
			}
		},

		return_to_admin_main() {
			this.$router.push({ path: '/admin' })
		},
	}
}
</script>

<style lang="scss">
.k-site-issues-table {
	th {
		white-space: nowrap;
	}

	th:last-child {
		display: none;
	}

	td {
		font-size: 14px !important;
		vertical-align: top;
		padding-top: 8px !important;
		padding-bottom: 8px !important;
	}

	.k-site-issues-table-notes {
		width: 360px;
		border: 1px solid #ccc;
		background-color: #fff;
		border-radius: 4px;
		padding: 4px;
		font-size: 14px;
		text-align: left;
	}
}
</style>
