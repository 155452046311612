import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: '#206166',		// from launchpad		// light-green darken-3
				// secondary: '#5c6bc0',	// indigo lighten-1
				secondary: '#ef6400',	// orange doe color
			}
		}
	}
});
