<!-- Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams -->
<template>
	<div>
		<v-dialog v-model="dialog_open" :max-width="is_flex_unit?1200:1000" persistent scrollable
			content-class="k-collection-unit-editor-dialog"> <!-- class needed for ResourceCollectionItem -->
			<v-card>
				<v-card-title style="border-bottom:1px solid #999">
					<b>Edit {{is_instance_unit?'Instance Unit Content':'Content Collection Unit'}}</b>
					<v-spacer/>
					<div v-if="show_flex_unit_toggle" class="d-flex align-center">
						<v-checkbox hide-details class="ma-0 pa-0" v-model="unit.is_flex_unit" label="Flex Unit"></v-checkbox>
						<b style="font-size:14px" class="pink--text text--darken-3 ml-2">BETA</b>
						<v-icon color="light-blue" class="ml-2" @click="flex_unit_help">fas fa-info-circle</v-icon>
					</div>
				</v-card-title>
				<v-card-text class="mt-3" style="font-size:16px; color:#000;">
					<v-row v-if="!is_default_collection">
						<v-col cols="2"><b>{{is_instance_unit?'Instance ':''}}Unit Title:</b> <span
								v-if="collection.units.length == 1">(optional)</span></v-col>
						<v-col cols="9">
							<v-text-field background-color="#fff" dense hide-details outlined label=""
								v-model="unit.title"></v-text-field>
							<div v-if="collection.use_unit_numbers || collection.use_unit_intervals"
								class="d-flex mt-3">
								<v-text-field dense v-if="collection.use_unit_numbers" background-color="#fff"
									class="mx-1" hide-details outlined label="Number (e.g. “Unit 1”)"
									style="flex-basis:25%" v-model="unit.display_number"
									@change="unit_number_changed(unit)">
								</v-text-field>
								<v-text-field dense v-if="collection.use_unit_intervals" background-color="#fff"
									class="mx-1" hide-details outlined label="Duration (in weeks)"
									style="flex-basis:15%" v-model="unit.duration">
								</v-text-field>
							</div>
						</v-col>
					</v-row>

					<v-row v-if="!is_default_collection">
						<v-col cols="2"><b>{{is_instance_unit?'Instance ':''}}Unit Description:</b> <span
								v-if="collection.units.length == 1">(optional)</span></v-col>
						<v-col cols="9" style="position:relative; z-index:2"
							class="k-collection-description-froala-wrapper-wrapper"><froala-wrapper
								:config="editor_config('Unit Long Description')" v-model="unit.text" /></v-col>
					</v-row>

					<div v-if="is_flex_unit" class="mt-3">
						<v-row class="align-center">
							<v-col cols="2">
								<div><nobr><b>Instance Units</b> <span style="font-size:14px">({{ instance_units.length }})</span></nobr></div>
							</v-col>
							<v-col cols="9" class="d-flex align-center">
								<v-btn x-small class="k-tight-btn elevation-0 mr-3" color="#555" dark
									@click="create_instance_unit">
									<v-icon x-small class="mr-1">fas fa-plus</v-icon>
									Add instance unit
								</v-btn>
								<v-spacer/>
								<v-text-field v-if="this.instance_units.length > 1" v-model="search"
									prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
									label="Search instance units" single-line hide-details outlined dense
									background-color="#fff" style="flex:0 1 450px"></v-text-field>
							</v-col>
						</v-row>
						<div>
							<v-data-table v-if="this.instance_units.length > 0" :headers="instance_unit_list_headers"
								:items="instance_unit_list_rows" :custom-filter="table_search_filter" :search="search"
								:items-per-page="5" sort-by="title" :sort-desc="false">
								<template v-slot:item="{ item }">
									<tr>
										<td>
											<v-btn icon x-small class="mr-2" @click="edit_instance_unit(item)">
												<v-icon>fas fa-edit</v-icon>
											</v-btn>
											<v-btn icon x-small class="mr-2" @click="delete_instance_unit(item)">
												<v-icon>fas fa-trash</v-icon>
											</v-btn>
											<span v-html="item.title"></span>
										</td>
										<td class="k-instance-unit-list-column">{{ item.admin_rights }}</td>
										<td class="k-instance-unit-list-column">{{ item.edit_rights }}</td>
										<td class="k-instance-unit-list-column">{{ item.view_rights }}</td>
									</tr>
								</template>
							</v-data-table>
						</div>
					</div>

					<div v-if="!is_default_collection" class="mt-3 k-collection-unit-editor-standards">
						<div class="d-flex align-center lp_unit_editor_standard_div">
							<div><b>Standards and Elements ({{ unit.standards.length }})</b></div>
							<v-btn v-show="unit.standards.length > 0" @click="standards_showing = !standards_showing"
								x-small class="ml-2" color="#ccc">{{ standards_showing ? 'Hide' : 'Show' }}
							</v-btn>
							<v-spacer />
							<v-btn v-show="!aligning_to_standards" class="mt-2" small color="#555" dark
								@click="align_to_standard(null)">
								<v-icon class="mr-1" small>fas fa-bullseye</v-icon>Edit Aligned Standards</v-btn>
							<v-btn v-show="aligning_to_standards" class="mt-2" small color="#555" dark
								@click="finish_aligning_to_standards">
								<v-icon class="mr-1" small>fas fa-check</v-icon>Done Aligning</v-btn>
						</div>

						<v-expand-transition>
							<div v-show="standards_showing && unit.standards.length > 0"
								style="background-color:#fff; padding:2px 8px; border-radius:6px; margin-top:8px;">
								<draggable v-bind="drag_options" v-model="unit.standards" @end="drag_complete">
									<div v-for="(standard) in unit.standards" class="k-lp-editor-standard-resource">
										<div class="d-flex align-center">
											<div class="k-lp-editor-standard-description"
												@click="align_to_standard(standard)"><b
													v-html="standard.case_item.humanCodingScheme"></b> &nbsp; <span
													v-html="standard.case_item.fullStatement"></span>
											</div>
											<v-btn icon small color="red lighten-2" class="ml-2"
												@click="remove_standard(standard)">
												<v-icon>fas fa-times-circle</v-icon>
											</v-btn>
											<v-icon small class="k-move-handle ml-2 mr-2" @click.stop="">fas
												fa-up-down-left-right
											</v-icon>
										</div>
									</div>
								</draggable>
							</div>
						</v-expand-transition>
					</div>

					<div :class="is_default_collection ? 'mt-2' : 'mt-6'">
						<div class="d-flex align-center" v-if="!is_default_collection">
							<h3>{{is_flex_unit?'Template ':''}}{{is_instance_unit?'Instance':''}} Unit Resources</h3>
							<v-btn class="ml-4 k-tight-btn" small color="#555" dark text
								@click="allow_resource_reordering = !allow_resource_reordering">
								<span
									v-html="allow_resource_reordering ? 'Stop Reordering' : 'Reorder resources and folders'">
								</span>
								<v-icon small class="ml-2">fas fa-arrows-alt</v-icon>
							</v-btn>
							<v-btn v-if="enable_add_tcc_btn" class="ml-4 k-tight-btn" small color="#555" dark text
								@click="add_resource_collection_folder">
								<v-icon small class="mr-2">fas fa-plus</v-icon>Add Common Cartridge Folder
							</v-btn>
						</div>

						<div
							:style="is_default_collection ? '' : 'border-top:1px solid #ccc; margin-top:16px; padding-top:8px;'">
							<CollectionResourceFolder :collection="collection" :unit="unit" :folder_id="'top'"
								:enable_collection_editing="true" :allow_resource_reordering="allow_resource_reordering"
								:folder_being_edited="true" :force_full_width="true"
								@edit_resource_saved="edit_resource_saved" @save_unit_edits="save_edits" />
						</div>
					</div>

				</v-card-text>

				<v-card-actions style="border-top:1px solid #999">
					<v-btn v-if="is_instance_unit && user_is_instance_unit_admin" color="primary" @click="edit_instance_unit(unit)" class="mr-1">
						<v-icon small class="mr-2">fas fa-user-cog</v-icon> Manage Access</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="cancel_editor" class="mr-1">
						<v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
					<v-btn color="primary" @click="save_edits('close')">
						<v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CollectionInstanceUnitEdit v-if="show_instance_unit_editor" @restore_editor="restore_editor"
			:collection="collection" :flex_unit="unit" :original_instance_unit="original_instance_unit"
			@save_instance_unit="save_instance_unit" />
	</div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import CollectionResourceFolder from './CollectionResourceFolder'
import CollectionInstanceUnitEdit from './CollectionInstanceUnitEdit.vue'

export default {
	components: { CollectionResourceFolder, CollectionInstanceUnitEdit, draggable },
	props: {
		collection: { type: Object, required: true },
		original_unit: { type: Object, required: true },
		instance_units: { type: Array, required: true, default() { return []} },
	},
	data() { return {
		dialog_open: true,
		grade_low: this.collection.grade_low,
		grade_high: this.collection.grade_high,
		subject: this.collection.subject,

		// create a new unit to edit here; then we copy it into the original_unit when user saves
		unit: new LP_Unit(this.original_unit),

		initial_values: JSON.stringify(this.original_unit.copy_for_save()),

		standards_showing: false,
		aligning_to_standards: false,

		drag_options: {
			animation: 200,
			handle: ".k-move-handle",
		},

		allow_resource_reordering: false,

		resource_import_rules: U.local_storage_get('district_portal_resource_import_rules', []),
		show_instance_unit_editor: false,
		original_instance_unit: null,
		instance_unit_list_headers: [
			{ text: 'Title', align: 'left', sortable: true, value: 'title' },
			{ text: 'Admins', align: 'left', sortable: true, value: 'admins' },
			{ text: 'Editors', align: 'left', sortable: true, value: 'editors' },
			{ text: 'Viewers', align: 'left', sortable: true, value: 'viewers', class: 'text-wrap' },
		],
		search: '',
		}
	},
	computed: {
		...mapState(['user_info']),
		is_default_collection() { return (this.collection.course_code == 'default') },
		// we show the btn to allow the user to add a common cartridge folder if...
		enable_add_tcc_btn() {
			// we have a common cartridge loaded for the collection
			if (this.collection.resource_collection_ids.length == 0) return false

			// and the unit doesn't already have a common cartridge folder
			if (this.unit.resource_tree.folders.find(f=>f.has_rcis == true)) return false
			
			return true
		},
		is_flex_unit() {
			return this.unit?.is_flex_unit ?? false
		},
		is_instance_unit() {
			return this.unit?.instance_unit_of !== 0 ?? false
		},
		user_is_instance_unit_admin() {
			if (!this.is_instance_unit) return false
			if (vapp.has_admin_right('su') || this.collection.user_is_lp_admin()) return true
			return this.user_info.admin_rights.includes(`admin_instance.unit.${this.unit.lp_unit_id}`)
		},
		show_flex_unit_toggle() {
			if (this.$store.state.site_config.enable_flex_units != 'yes') return false
			if (this.is_instance_unit) return false
			if (vapp.has_admin_right('su') || this.collection.user_is_lp_admin()) return true
			return false
		},
		instance_unit_list_rows() {
			const create_row = ({ title, lp_unit_id, domain_rights = [], user_rights = [] }) => {
				const rights = [...domain_rights.map((r) => { return { rights: r.rights, identifier: r.identifier } }), ...user_rights.map((r) => { return { rights: r.rights, identifier: r.email } })]
				const admin_rights = rights.filter(right => right.rights.startsWith('admin_')).map(right => right.identifier).join(', ')
				const edit_rights = rights.filter(right => right.rights.startsWith('edit_')).map(right => right.identifier).join(', ')
				const view_rights = rights.filter(right => right.rights.startsWith('view_')).map(right => right.identifier).join(', ')

				return { lp_unit_id, title, admin_rights: admin_rights, edit_rights: edit_rights, view_rights: view_rights }
			}
			return Object.values(this.instance_units).map(create_row)
		},
	},
	created() {
		// get lesson_masters if we haven't already gotten done so
		this.$store.dispatch('get_lesson_masters')
	},
	mounted() {
		vapp.collection_unit_editor = this
	},
	methods: {
		editor_config(text, inline) {
			return U.get_froala_config({
				placeholderText: text,
				zIndex: 1000,
			})
		},

		restore_editor() {
			this.show_instance_unit_editor = false
		},

		unit_number_changed(unit) {
			// if user enters just a number, change to "Unit #"
			if (!isNaN(unit.title*1)) {
				unit.title = 'Unit ' + unit.title
			}
		},

		// Standards
		align_to_standard(start_item) {
			// standards_showing should always be true when we're aligning
			this.standards_showing = true

			// we currently save unit standards with the LP_Unit_Standard class, though the only thing we use from that class is the case_item
			// also note that the case_item includes the framework_identifier, for standards chosen starting 1/2023
			// there are a number of places below where use, e.g., "standard.case_item" for this reason
			if (start_item?.case_item) start_item = start_item.case_item

			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// trace up through parents looking for a case_framework_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// trace up through parents looking for a course_case_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.course_case_identifier) { data.item_identifier = parent.course_case_identifier; break; }
					parent = parent.$parent
				}
			}

			// add current unit standards as selected items
			if (this.unit.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.unit.standards) data.selected_items.push(standard.case_item.identifier)
			}

			let show_data = { 
				// set embed_hide_callback_fn to toggle aligning_to_standards off when the user closes the chooser
				embed_hide_callback_fn: ()=>{ this.aligning_to_standards = false },
				// set hide_fn to hide the standards chooser if/when the editor is no longer visible
				hide_fn: ()=>{ return ($(vapp.collection_unit_editor?.$el).is(':visible') == false) },
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.unit.standards.findIndex(o=>o.case_item.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.unit.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard and the framework_identifier, currently using the LP_Unit_Standard construct
							aligned_item.cfitem.framework_identifier = aligned_item.framework_identifier
							let o = new LP_Unit_Standard({identifier: aligned_item.cfitem.identifier, case_item: aligned_item.cfitem})
							this.unit.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
						
						// save immediately, unless this is a new lesson -- currently can't do this, as this editor always closes on save
						// if (!this.is_new_lesson) this.save_lesson('no_spinner')
					})
				})
			}).catch(()=>this.finish_aligning_to_standards())	// this will execute when the standards are hidden

			this.aligning_to_standards = true
		},

		remove_standard(standard) {
			let i = this.unit.standards.findIndex(o=>o==standard)
			if (i > -1) this.unit.standards.splice(i, 1)
		},

		finish_aligning_to_standards() {
			vapp.$refs.satchel.execute('hide')
			this.aligning_to_standards = false
		},

		drag_complete(evt) {
			// standards will have been reordered; just need to save
			// currently can't save, as this editor always closes on save
			// if (!this.is_new_lesson) this.save_lesson('no_spinner')
		},

		hide_case_tree() {
			// might need this...
		},

		add_resource_collection_folder() {
			// for now we'll assume only the first-listed resource_collection can have folders; in the future we could let the user choose
			let rc = this.collection.resource_collections[0]
			let rcis = {}
			rcis[rc.resource_id] = []		// by setting this to an empty array, we by default load everything

			this.unit.create_resource_folder({parent_folder_id:'top', title:rc.description, color:'indigo', rcis: rcis})

			// save edits, then refresh to force the tcc folder selection interface to show
			this.save_edits('refresh')
		},

		unit_has_changed() {
			return JSON.stringify(this.unit.copy_for_save()) != this.initial_values
		},

		// this is called when an existing resource is edited; ResourceCollectionItem will save the edit to the DB, but we have to update the original_unit resource here in case nothing else in the unit changes
		edit_resource_saved(updated_resource) {
			// transfer all properties from the updated_resource to the local resource and the one in the original unit
			let original_resource = this.original_unit.resources.find(o=>o.resource_id == updated_resource.resource_id)
			for (let prop in updated_resource) {
				this.$store.commit('set', [original_resource, prop, updated_resource[prop]])
			}
		},

		save_instance_unit(instance_unit, original_instance_unit = null) {
			return new Promise((resolve, reject) => {
				U.loading_start()
				const payload = {
					user_id: this.user_info.user_id,
					lp_id: this.collection.lp_id,
					instance_unit_of: this.original_unit.lp_unit_id,
					instance_unit_title: instance_unit.title,
					// If this is a new instance unit we are creating, we will send a zero to the service
					// If it is an existing one we are editing, we send the lp_unit_id
					lp_unit_id: original_instance_unit?.lp_unit_id ?? 0,
				}
				U.ajax('save_instance_unit', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); return;
					}
					this.$emit('editor_refresh')
					resolve()
				})
			})
		},

		delete_instance_unit(instance_unit) {
			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to delete this instance unit?',
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				let payload = { user_id: this.user_info.user_id, lp_unit_id: instance_unit.lp_unit_id }
				U.ajax('delete_instance_unit', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); return;
					}
					// Refresh list of instance units
					this.$emit('get_instance_units')
				});
			}).catch(n => { console.log(n) }).finally(f => { })
		},

		save_edits(flag) {

			// If we are adding items from search 'and' we are in an instance unit,
			// we just need to run the edit_instance_unit service with the udpated unit data
			if (this.is_instance_unit && flag === "add_items_from_search") {
				const payload = {
					user_id: this.user_info.user_id,
					unit_data: JSON.stringify(this.unit.copy_for_save())
				}
				U.ajax('edit_instance_unit', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); return;
					}
					this.$emit('get_instance_units')
					return
				})

			}

			return new Promise((resolve, reject) => {
				if (!this.unit_has_changed()) {
					console.log('nothing changed (unit editor), so not saving')
					if (flag == 'close') this.cancel_editor()
					resolve()
					return
				}

				if (this.collection.units.length > 1 && empty(this.unit.title)) {
					this.$alert('The unit must have a title.')
					reject()
					return
				}

				// trim whitespace from unit description
				this.unit.text = window.trim_froala_text(this.unit.text)

				// Things are different for Instance Units because they don't live in collection.units.
				// We need to just make the service call here directly and then refresh
				if (this.is_instance_unit) {
					const lpu = new LP_Unit(this.unit)
					const payload = {
						user_id: this.user_info.user_id,
						unit_data: JSON.stringify(lpu.copy_for_save())
					}
					U.ajax('edit_instance_unit', payload, result => {
						U.loading_stop()
						if (result.status != 'ok') {
							console.log('Error in ajax call'); vapp.ping(); return;
						}
						this.$emit('get_instance_units')
						this.cancel_editor()
						resolve()
					})
				} else {
					this.$store.commit('set', [this.original_unit, 'title', this.unit.title])
					this.$store.commit('set', [this.original_unit, 'duration', this.unit.duration])
					this.$store.commit('set', [this.original_unit, 'display_number', this.unit.display_number])
					this.$store.commit('set', [this.original_unit, 'text', this.unit.text])
					this.$store.commit('set', [this.original_unit, 'standards', this.unit.standards])
					this.$store.commit('set', [this.original_unit, 'resource_collection_inclusions', this.unit.resource_collection_inclusions])
					this.$store.commit('set', [this.original_unit, 'resources', this.unit.resources])
					this.$store.commit('set', [this.original_unit, 'lessons', this.unit.lessons])
					this.$store.commit('set', [this.original_unit, 'resource_tree', this.unit.resource_tree])
					this.$store.commit('set', [this.original_unit, 'is_flex_unit', this.unit.is_flex_unit ?? false])

					console.log('save_learning_progression from CollectionUnitEdit; lessons:', this.unit.lessons)

					// if we're editing a "real" collection, dispatch save_learning_progression
					if (!this.is_default_collection) {
						this.$store.dispatch('save_learning_progression', this.collection).then(() => {
							if (flag == 'close') {
								this.cancel_editor()
							} else if (flag == 'refresh') {
								this.$emit('editor_refresh')
							}

							// it's possible that the lp_unit_id was updated; if so, refresh the window
							let new_path = window.location.pathname.replace(/(\/.*?\/)\d+$/, '$1' + this.original_unit.lp_unit_id)
							if (new_path != window.location.pathname) {
								// console.log('REPLACE PATH??? ' + new_path)
								// window.document.location.replace(new_path)
								// return
							}

							// reset initial_values to original_unit, which may be updated, so that the next time we save, unit_has_changed will work properly
							this.initial_values = JSON.stringify(this.original_unit.copy_for_save())

							// set unit.full_description_height to -1 so that the description resizer re-runs
							this.$store.commit('set', [this.original_unit, 'full_description_height', -1])

							resolve()
						})

						// else we're editing the default collection...
					} else {
						this.$store.dispatch('save_default_collection_resources').then(() => {
							// Note: lessons/resources will be put back in alpha order the next time the user reloads

							if (flag == 'close') {
								this.cancel_editor()
							}

							// reset initial_values to original_unit, which may be updated, so that the next time we save, unit_has_changed will work properly
							this.initial_values = JSON.stringify(this.original_unit.copy_for_save())

							resolve()
						})
					}
				}


			})
		},

		cancel_editor(edit_action) {
			this.$emit('editor_cancel')
		},

		create_instance_unit() {
			this.original_instance_unit = {} // clear previously selected instance unit
			this.show_instance_unit_editor = true
		},

		edit_instance_unit(instance_unit) {
			this.original_instance_unit = instance_unit
			this.show_instance_unit_editor = true
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check title
			if (item.title.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		flex_unit_help() {
			let s = ''
			// s += `Flex units are Collaboration Collections that live within Courses and Repositories. These flexible units can house one or more Collaborative Collections that will only be visible to those with access. Each Collaboration Collection has their own ADMIN, EDITORS, and VIEWERS for their unique group purpose. Email domains can also be used to grant viewing visibility. Collaboration Collections could be used by a district or by many schools within a district. Reach out to ... for more information.`
			s += `This BETA feature of ${this.$store.state.site_config.app_name} allows for “flexible” content to be deployed…`
			this.$alert({title: 'Flex Units', text: s})
		},
	}
}
</script>

<style lang="scss">
.k-collection-unit-editor-instance-units {
	background-color: #fff;

	.k-collection-unit-editor-instance-unit {
		border-radius: 6px;
		padding: 8px;
		background-color: #eee;
		margin-top: 8px;

		.k-collection-unit-editor-instance-unit--title {
			flex: 1 1 auto;
			font-size: 14px;
			font-weight: bold;
			line-height: 18px;
		}
	}
}

.k-collection-unit-editor-standards {
	.k-lp-editor-standard-resource {
		padding: 5px;
		margin: 10px 0;
		border-radius: 4px;
		background-color: #eee;
	}

	.k-lp-editor-standard-description {
		flex: 1 1 auto;
		cursor: pointer;
		font-size: 14px;
		line-height: 18px;
		// margin-bottom:8px;
		// overflow:hidden;
		// white-space:nowrap;
	}

	.k-lp-editor-standard-text {
		margin-top: 8px;
		padding-top: 8px;
		border-top: 1px solid #ccc;
		font-size: 14px;
		line-height: 18px;
	}
}

.k-instance-unit-list-column {
	width: 20%;
	font-size:12px!important;
	line-height:16px!important;

	span {
		white-space: normal;
		word-wrap: break-word;
	}
}
</style>
