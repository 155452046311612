<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<div class="k-main-collection">
		<div class="d-flex">
			<h2 class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-diagram-project</v-icon><b>{{page_title}}</b></h2>
			<v-spacer/>
			<v-btn v-if="signed_in && !reorder_toggle" color="secondary" class="k-tight-btn k-nocaps-btn" @click="go_to_favorites"><v-icon small class="mr-2">fas fa-diagram-project</v-icon>Favorite Collections</v-btn>
			<v-btn v-if="signed_in && user_is_superuser" color="secondary" class="ml-2 k-tight-btn k-nocaps-btn" @click="reorder_toggle=!reorder_toggle"><v-icon small class="mr-2">fas fa-up-down-left-right</v-icon>{{reorder_toggle ? 'Stop Reordering' : 'Reorder'}}</v-btn>
		</div>
		<div v-show="initialized">
			<!-- this is unlikely to happen... -->
			<div v-if="collection_lps.length==0">There are currently no collections available.</div>
			<div v-if="collection_lps.length>0" style="width:500px; margin:auto;" class="mb-4"><v-text-field v-model="search_string" prepend-inner-icon="fa fa-search" rounded clearable clear-icon="fa fa-times-circle" label="Search collection titles…" single-line hide-details outlined dense background-color="#fff"></v-text-field></div>

			<div v-if="reorder_toggle">
				<!-- note that `draggable=".k-repo-category-draggable"` makes it so that the default categories can't be dragged; but lps in the default categories can be dragged -->
				<draggable v-bind="drag_options" v-model="category_order" @change="category_order_changed" draggable=".k-repo-category-draggable" class="d-flex flex-wrap justify-center">
					<div v-for="(name, i) in category_order" :key="i" class="k-repo-category k-repo-category-draggable" v-if="name in collection_categories">
						<div class="k-repo-category-label">
							<v-icon style="margin-top:-4px; font-size:18px;" class="mr-2">fas fa-up-down-left-right</v-icon>
							{{ collection_categories[name].display_cat_name }}
							<v-icon style="margin-top:-4px; font-size:18px;" class="ml-2" @click="edit_category_title(collection_categories[name])">fas fa-edit</v-icon>
						</div>
						<draggable v-bind="drag_options" class="d-flex flex-wrap justify-center" group="categories" :data-list-name="name" :list="collection_categories[name].lps" @change="repo_order_changed(name, collection_categories[name].lps), $event">
							<div v-for="(lp) in collection_categories[name].lps" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]">
								<span v-html="lp_title(lp)"></span>
								<div style="position:absolute; right:12px; bottom:12px; font-size:14px;">
									<v-icon @click="edit_lp_category_title(lp)">fas fa-edit</v-icon>
									<v-icon>fas fa-up-down-left-right</v-icon>
								</div>
							</div>
						</draggable>
					</div>
					<div v-if="default_repos.default_pd_repo_cat.lps.length > 0" key="default_pd" class="k-repo-category">
						<div class="k-repo-category-label" v-html="default_repos.default_pd_repo_cat.display_cat_name"></div>
						<draggable v-bind="drag_options" class="d-flex flex-wrap justify-center" group="categories" data-list-name="default_pd" :list="default_repos.default_pd_repo_cat.lps" @change="repo_order_changed('default_pd', default_repos.default_pd_repo_cat.lps), $event">
							<div v-for="lp in default_repos.default_pd_repo_cat.lps" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]">
								<span v-html="lp_title(lp)"></span>
								<div style="position:absolute; right:12px; bottom:12px; font-size:14px;">
									<v-icon @click="edit_lp_category_title(lp)">fas fa-edit</v-icon>
									<v-icon>fas fa-up-down-left-right</v-icon>
								</div>
							</div>
						</draggable>
					</div>
					<div v-if="default_repos.default_repo_cat.lps.length > 0" key="default_repo" class="k-repo-category">
						<div class="k-repo-category-label" v-html="default_repos.default_repo_cat.display_cat_name"></div>
						<draggable v-bind="drag_options" class="d-flex flex-wrap justify-center" group="categories" data-list-name="default_repo" :list="default_repos.default_repo_cat.lps" @change="repo_order_changed('default_repo', default_repos.default_repo_cat.lps, $event)">
							<div v-for="lp in default_repos.default_repo_cat.lps" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]">
								<span v-html="lp_title(lp)"></span>
								<div style="position:absolute; right:12px; bottom:12px; font-size:14px;">
									<v-icon @click="edit_lp_category_title(lp)">fas fa-edit</v-icon>
									<v-icon>fas fa-up-down-left-right</v-icon>
								</div>
							</div>
						</draggable>
					</div>
				</draggable>
			</div>
			<div v-else class="d-flex flex-wrap justify-center">
				<div v-for="(name, i) in category_order" :key="i" class="k-repo-category">
					<template v-if="name in collection_categories">
						<div class="k-repo-category-label" v-html="collection_categories[name].display_cat_name"></div>
						<div v-for="(lp) in collection_categories[name].lps" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]" v-html="lp_title(lp)"></div>
					</template>
				</div>
				<div v-if="default_repos.default_pd_repo_cat.lps.length > 0" class="k-repo-category">
					<div class="k-repo-category-label" v-html="default_repos.default_pd_repo_cat.display_cat_name"></div>
					<div v-for="lp in default_repos.default_pd_repo_cat.lps" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]" v-html="lp_title(lp)"></div>
				</div>
				<div v-if="default_repos.default_repo_cat.lps.length > 0" class="k-repo-category">
					<div class="k-repo-category-label" v-html="default_repos.default_repo_cat.display_cat_name"></div>
					<div v-for="lp in default_repos.default_repo_cat.lps" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]" v-html="lp_title(lp)"></div>
				</div>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import CollectionEdit from '../collections/CollectionEdit'
import MiniNav from '../main/MiniNav'
import draggable from 'vuedraggable'

export default {
	components: { draggable, MiniNav },	// CollectionEdit
	data() { return {
		initialized: false,
		search_string:'',
		search_terms: [],
		category_order: [],
		default_repos: {
			default_repo_cat: {
				display_cat_name: "Resource Repositories",
				repo_in_category: false,
				lps: [],
			},
			default_pd_repo_cat: {
				display_cat_name: "PD Repositories",
				pd_in_category: false,
				lps: [],
			},
			loaded: false
		},
		drag_options: {
			animation: 200,
		},
		reorder_toggle: false,
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['small_screen', 'signed_in', 'studentish_role']),
		page_title() {
			let s = this.site_config.resourcerepos_noun
			if (!this.studentish_role && this.site_config.show_pd == 'yes') {
				s += ' and PD Collections'
			}
			return s
		},
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		collection_lps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
				if (lp.active == 'no') {
					if (!lp.user_can_view_lp()) return false		// && !this.is_repo_admin
				}
				
				// only include collection_types 'repo' and 'pd' here...
				if (!['repo', 'pd'].includes(lp.collection_type)) return false

				// and parents can only see repos (that are designed for viewing by them?)
				if (this.studentish_role) {
					if (lp.collection_type == 'pd') return false
				}

				return true
			})
			return arr
		},
		collection_lps_to_show() {
			let arr = this.collection_lps.filter(lp => {
				// if we have repositories_to_include, limit to those
				if (this.site_config.repositories_to_include && !this.site_config.repositories_to_include.includes(lp.course_code)) {
					// but *DO* include repositories updated after include_lps_updated_after (if set), even if they aren't in the list to include
					if (empty(this.site_config.include_lps_updated_after) || lp.updated_at < this.site_config.include_lps_updated_after) {
						return false
					}
				}

				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				return true
			})

			arr.sort((a,b)=>{
				// // featured collections first -- NO, not using featured in Inspire for now
				// if (a.featured && !b.featured) return -1
				// if (b.featured && !a.featured) return 1

				if (a.sequence != b.sequence) return a.sequence - b.sequence

				// if both are featured, most-recently-featured first
				if (a.featured && b.featured) return b.featured - a.featured

				// default sort by title
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},

		collection_categories() {
			// MC: the categories are stored with the key={full_category_name}, full_category_name = 5_cat name,
			// 	   where the number represents the order of it and the values is lps, and the display name
			//     we do this so we can populate the category_order by simply sorting the keys
			let cats = {}
			for (let collection of this.collection_lps_to_show) {
				if (collection.repo_category) {
					if (collection.collection_type == 'pd') this.default_repos.pd_in_category = true
					else this.default_repos.repo_in_category = true
					const display_cat_name = collection.repo_category.split('_').slice(1).join('_');
					if (!cats[collection.repo_category]) {
						cats[collection.repo_category] = {}
						cats[collection.repo_category].lps = []
						cats[collection.repo_category].display_cat_name = display_cat_name
					}
					cats[collection.repo_category].lps.push(collection)

				} else if (collection.collection_type == 'repo') {
					if (!this.default_repos.loaded) this.default_repos.default_repo_cat.lps.push(collection)

				} else {
					if (!this.default_repos.loaded) this.default_repos.default_pd_repo_cat.lps.push(collection)
				}
			}

			// sort each category
			for (let key in cats.lps) {
				cats[key].sort((a,b)=>U.natural_sort(a.title, b.title))
			}

			if (this.default_repos.pd_in_category) this.default_repos.default_pd_repo_cat.display_cat_name = "Other PD Repositories"
			if (this.default_repos.repo_in_category) this.default_repos.default_repo_cat.display_cat_name = "Other Repositories"
			this.default_repos.loaded = true

			setTimeout(x=>{
				// for some reason, doing this makes moving default lps to other categories work properly
				this.default_repos.default_repo_cat.lps.sort((a,b)=>U.natural_sort(a.title, b.title))
				this.default_repos.default_pd_repo_cat.lps.sort((a,b)=>U.natural_sort(a.title, b.title))
			}, 100)

			return cats
		},

		is_repo_admin() {
			return vapp.has_admin_right('repo')
		},
		user_is_superuser() {
			return vapp.has_admin_right('su')
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		}
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses', 'initial').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
					this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b))
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialized = true
			this.category_order = Object.keys(this.collection_categories).sort()
		}
		vapp.repo_index_component = this
	},
	mounted() {
	},
	methods: {
		go_to_favorites() {
			window.history.replaceState(null, '', '/repos')
			if (!this.signed_in) this.$store.commit('lst_set', ['unsigned_index_view_flavor', 'favorites'])
			else this.$store.commit('lst_set', ['repo_index_view_flavor', 'favorites'])
		},

		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			// let s = U.subject_tile_css(lp)
			let s = ''

			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}

			return s
		},

		lp_style(lp) {
			return U.collection_color_style(lp)
		},

		lp_title(lp) {
			let s = ''
			s += '<div>'
			const contrast_color = U.get_contrast_color(lp.color)
			
			// move icon if reordering
			// if (this.reorder_toggle) s += '<div><i style="position:absolute; right:12px; bottom:12px; font-size:14px;" class="fas fa-up-down-left-right"></i></div>'

			// start with icon indicating collection type
			if (lp.collection_type == 'course') s += `<i style="color: ${contrast_color}" class="fas fa-chalkboard"></i>`
			else if (lp.collection_type == 'repo') s += `<i style="color: ${contrast_color}" class="fas fa-diagram-project"></i>`
			else if (lp.collection_type == 'pd') s += `<i style="color: ${contrast_color}" class="fas fa-user-graduate"></i>`
			else s += `<i style="color: ${contrast_color}" class="fas fa-cubes-stacked"></i>`
			// add <wbr> tags after slashes
			s += lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'

					// s += ' <b class="red--text">[Inactive]</b>'
				}
			s += '</div>'

			return s
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: lp.vue_route() })
		},

		// this is called when the categories are reordered; this.category_order value will reflect the new order. here we have to check, and possibly change, the repo_category values of all lps
		category_order_changed() {
			let payload = {user_id: this.user_info.user_id, lps_to_update: []}

			// for each category...
			for (let i = 0; i < this.category_order.length; ++i) {
				// get old name and construct new name, which incorporates the sequence number at the start
				let old_repo_category = this.category_order[i]
				let new_repo_category = (100+i) + '_' + old_repo_category.replace(/^\d+_/, '')	// first category will be 100, second will be 101, etc.

				// if category name changes, we have to update all lps with the old category to have the new category
				if (old_repo_category != new_repo_category) {
					for (let lp of this.collection_lps) {
						if (lp.repo_category == old_repo_category) {
							this.$store.commit('set', [lp, 'repo_category', new_repo_category])
							// no need to change the sequence of item in the category

							payload.lps_to_update.push({course_code: lp.course_code, repo_category: new_repo_category})
						}
					}
				}
			}
			this.update_lp_categories(payload)

			// update the category_order computed to refresh the display; on next tick so that the collection_categories computed has time to re-run
			this.$nextTick(x=>this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b)))
		},

		// this is called when an LP is dragged from one position to another. if the LP is moved within a category, it'll just be called once; if it's moved between categories, it'll be called twice: for the "to" cat and the "from" cat
		// in both cases, 'category_name' will be the name of the category, and 'new_order' will be the new list of LPs in the category
		repo_order_changed(category_name, new_order, $event) {
			// console.log('repo_order_changed', category_name, new_order, $event)
			const new_order_course_codes = new_order.map(({course_code}) => course_code)

			let payload = {user_id: this.user_info.user_id, lps_to_update: []}
			for (let i = 0; i < new_order_course_codes.length; ++i) {
				let course_code = new_order_course_codes[i]
				let lp = this.all_courses.find(x=>x.course_code == course_code)
				if (!lp) { console.error('couldn’t find lp'); return; }	// shouldn't happen

				// when an lp is moved *into* a default category, this is called with the default category's list. in this case, we *remove* the repo_category, and set sequence to 0
				if (category_name == 'default_pd' || category_name == 'default_repo') {
					this.$store.commit('set', [lp, 'repo_category', ''])
					this.$store.commit('set', [lp, 'sequence', 0])

					payload.lps_to_update.push({course_code: course_code, repo_category: '', sequence: 0})

				} else {
					this.$store.commit('set', [lp, 'repo_category', category_name])
					this.$store.commit('set', [lp, 'sequence', i+1])	// lets make these 1-indexed

					payload.lps_to_update.push({course_code: course_code, repo_category: category_name, sequence: i})
				}
			}
			this.update_lp_categories(payload)
		},

		edit_category_title(cat) {
			console.log(cat)
			this.$prompt({
				title: 'Edit Category Title',
				text: 'Enter new category title:',
				initialValue: cat.display_cat_name,
				disableForEmptyValue: true,
				acceptText: 'Save',
				acceptIcon: 'fas fa-save',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				// construct the new category title, then replace in all LPs
				let payload = {user_id: this.user_info.user_id, lps_to_update: []}
				let new_repo_category = cat.lps[0].repo_category.replace(/^(\d+_).*/, '$1') + title
				for (let lp of cat.lps) {
					this.$store.commit('set', [lp, 'repo_category', new_repo_category])
					payload.lps_to_update.push({course_code: lp.course_code, repo_category: new_repo_category})
				}

				this.update_lp_categories(payload)

				// update the category_order computed to refresh the display; on next tick so that the collection_categories computed has time to re-run
				this.$nextTick(x=>this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b)))

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		edit_lp_category_title(lp) {
			this.$prompt({
				title: 'New Category',
				text: 'Enter a new category title for this collection:',
				initialValue: '',
				disableForEmptyValue: true,
				acceptText: 'Save',
				acceptIcon: 'fas fa-save',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				let new_repo_category = '999_' + title

				// if this category title already exists, use it (with its sequence number)
				for (let lp2 of this.collection_lps) {
					let rc_title = lp2.repo_category.replace(/^\d+_/, '')
					if (title == rc_title) {
						// this repo_category matches the entered one, so use it
						new_repo_category = lp2.repo_category
						break
					}
				}

				// replace repo category in this LP
				let payload = {user_id: this.user_info.user_id, lps_to_update: [{course_code: lp.course_code, repo_category: new_repo_category, sequence: 999}]}
				this.$store.commit('set', [lp, 'repo_category', new_repo_category])
				this.$store.commit('set', [lp, 'sequence', 999])

				this.update_lp_categories(payload)

				// update the category_order computed to refresh the display; on next tick so that the collection_categories computed has time to re-run
				this.$nextTick(x=>this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b)))

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		update_lp_categories(payload) {
			// note that we set category and/or sequence in the LP's $store representation before calling this; if the service fails (which it shouldn't), we'll alert an error
			// console.warn(payload); return
			U.ajax('update_lp_categories', payload, result=>{
				if (result.status != 'ok') {
					this.$alert('Error updating collection order: ' + result.status)
					vapp.ping()		// call ping to check if the session is expired
				}
			})
		}
		// create_lp(collection_type) {
		// 	// lps created from here are definitionally agency_sanctioned, and use the "tree" layout
		// 	this.new_lp = new Learning_Progression({
		// 		subject:'', 
		// 		agency_sanctioned: true, 
		// 		lp_layout: 'tree',
		// 		collection_type: collection_type,
		// 		active: 'no',
		// 		use_unit_numbers: false,
		// 		units: [new LP_Unit()],
		// 	})

		// 	return
		// },
	}
}
</script>

<style lang="scss">

.k-main-collection-sub-collection {
	border-radius: 40px;
	padding:4px;
	background-color:rgba(255, 255, 255, 0.5);
	margin-bottom:16px;
	display:inline-block;	// make it shrink to fit whatever is inside of it
	min-width:460px;
}

.k-main-collection-sub-collection-featured {
	background-color:rgba(255, 240, 200, 0.5);
	border:0;
}

.k-main-collection--course-showing {
	margin:0!important;
	padding:0px 10px!important;

	.k-lp-tile {
		width:100px!important;
		height:100px!important;
		opacity:0.6;
		margin:10px;
		background-color:#f8f8f8;
		-webkit-box-shadow: 0 0 0 0 !important;
	    box-shadow: 0 0 0 0 !important;
		font-size:12px!important;
		line-height:14px!important;
		font-weight:normal!important;
	}

	.k-lp-tile--repos-showing, .k-lp-tile--pd-showing {
		font-weight:900!important;
		background-color:#eee;
		opacity:1.0;
		-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.k-main-collection-sub-collection {
		-webkit-box-shadow: none!important;
		box-shadow:none!important;
		background-color:transparent;
		padding:0;
		margin-bottom:0;
		border:0px;
	}
}

.k-repo-category {
	position:relative;
	border-radius: 40px;
	padding:36px 12px 12px 12px;
	background-color:rgba(255, 255, 255, 0.5);
	margin:0 10px 20px 10px;
	min-width:240px;
	display:inline-flex;	// make it shrink to fit whatever is inside of it
	flex-wrap:wrap;
	flex-direction: row;
	align-items:center;
	justify-content: center;

	.k-main-collection-item {
		margin:8px;
	}
}

.k-repo-category-label {
	position:absolute;
	width:100%;
	text-align:center;
	top:12px;
	left:0;
	font-weight:bold;
	font-size:16px;
	line-height:20px;
	white-space:nowrap;
	// padding:0 4px;	// this is redundant if white-space is nowrap
}

</style>
