<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" max-width="800" persistent scrollable :retain-focus="false">
	<v-card>
		<v-card-title style="border-bottom:1px solid #999"><b style="font-weight:900; font-size:1.2em">
			<span v-if="is_new_lesson">New</span>
			<span v-else>Edit</span>
			{{lesson_noun}}
		</b></v-card-title>

		<v-card-text class="pt-4">
			<LessonMasterEditor v-if="lesson_class=='master'" :original_lesson="original_lesson" @edit_lesson_cancel="$emit('edit_lesson_cancel',$event)" @edit_lesson_saved="$emit('edit_lesson_saved',$event)" />
			<LessonEditor v-if="lesson_class!='master'" :original_lesson="original_lesson" :lesson_class="lesson_class" @edit_lesson_cancel="$emit('edit_lesson_cancel',$event)" @edit_lesson_saved="$emit('edit_lesson_saved',$event)" />
		</v-card-text>
	</v-card>
</v-dialog></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import LessonEditor from './LessonEditor'
import LessonMasterEditor from './LessonMasterEditor'

export default {
	components: { LessonEditor, LessonMasterEditor },
	props: {
		original_lesson: { required: true },	// if 'new' we're creating a new lesson
		lesson_class: { type: String, required: false, default() { return '' }},	// '', 'template', or 'master'
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		lesson_noun() {
			if (this.lesson_class == 'master') return 'Lesson Master'
			else if (this.lesson_class == 'template') return 'Template Lesson'
			else return 'Lesson'
		},
		is_new_lesson() { return this.original_lesson == 'new' },
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
