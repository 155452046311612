var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"k-main-division-wrapper k-main-welcome"},[_c('div',{staticClass:"k-main-welcome__question k-shadow-text"},[_c('b',[_vm._v(_vm._s(_vm.welcome_msg))])]),_vm._v(" "),_c('div',{staticClass:"k-main-welcome__time-date k-shadow-text",domProps:{"innerHTML":_vm._s(_vm.time_string)}}),_vm._v(" "),_c('div',{staticClass:"k-main-welcome--nav-btns"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tab_showing('home')),expression:"tab_showing('home')"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='home' || _vm.welcome_section_showing=='family_home'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('home')}}},on),[_c('v-icon',[_vm._v("fas "+_vm._s(_vm.role=='parent'?'fa-home-user':'fa-home'))])],1)]}}])},[_vm._v(_vm._s(_vm.site_config.app_name)+" Home")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tab_showing('classes')),expression:"tab_showing('classes')"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='classes'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('classes')}}},on),[_c('v-icon',[_vm._v("fas fa-chalkboard")])],1)]}}])},[_vm._v("Courses")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tab_showing('resourcerepos')),expression:"tab_showing('resourcerepos')"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='resourcerepos'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('resourcerepos')}}},on),[_c('v-icon',[_vm._v("fas fa-diagram-project")])],1)]}}])},[_vm._v(_vm._s(_vm.site_config.resourcerepos_noun))]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tab_showing('pd')),expression:"tab_showing('pd')"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='pd'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('pd')}}},on),[_c('v-icon',[_vm._v("fas fa-user-graduate")])],1)]}}])},[_vm._v("Professional Development")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tab_showing('mycollections')),expression:"tab_showing('mycollections')"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='mycollections'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('mycollections')}}},on),[_c('v-icon',[_vm._v("fas fa-cubes-stacked")])],1)]}}])},[_vm._v("My Content Collections")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.tab_showing('standards')),expression:"tab_showing('standards')"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='standards'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('standards')}}},on),[_c('v-icon',[_vm._v("fas fa-map")])],1)]}}])},[_vm._v("Standards")]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xs&&!_vm.$vuetify.breakpoint.sm&&_vm.role=='admin'),expression:"!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm&&role=='admin'"}],staticClass:"k-main-welcome--nav-btn",class:_vm.welcome_section_showing=='admin'?'k-main-welcome--nav-btn-current':'',on:{"click":function($event){return _vm.switch_welcome_section('admin')}}},on),[_c('v-icon',[_vm._v("fas fa-cog")])],1)]}}])},[_vm._v("Site Admin Tools")])],1),_vm._v(" "),_c('div',{staticClass:"k-main-welcome--pages"},[(_vm.welcome_section_showing=='home')?_c('HomeHome'):(_vm.welcome_section_showing=='standards')?_c('StandardsHome'):(_vm.welcome_section_showing=='admin')?_c('AdminList'):(_vm.welcome_section_showing=='family_home')?_c('FamilyHome'):(_vm.welcome_section_showing=='resource_usage')?_c('ResourceUsage'):_c('div',[(_vm.index_view_flavor=='favorites')?_c('FavoritesIndex'):_c('div',[(_vm.welcome_section_showing=='classes')?_c('CourseIndex',{ref:"lp_index_component"}):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='resourcerepos')?_c('RepositoriesIndex'):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='pd')?_c('PDIndex'):_vm._e(),_vm._v(" "),(_vm.welcome_section_showing=='mycollections')?_c('MyCollectionsIndex'):_vm._e()],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }