// Note that this file contains classes for both lessons and activities

class Lesson {
	constructor(data, master_data) {
		if (empty(data)) data = {}
		sdp(this, data, 'lesson_id', 0)

		sdp(this, data, 'agency_sanctioned', false)

		sdp(this, data, 'lesson_master_id', 0)
		sdp(this, data, 'lesson_template_id', 0)
		sdp(this, data, 'lesson_title', '')

		// lesson status is empty, 'archived', or 'deleted'
		// but note that we don't currently load archived or deleted lessons to the client, so we don't really need to store this here
		sdp(this, data, 'lesson_status', '')

		sdp(this, data, 'lesson_date', '')	// format: 2022-03-24; see date_header() below for how to convert this to a date object

		sdp(this, data, 'course_code', '')
		sdp(this, data, 'lp_unit_id', 0)
		sdp(this, data, 'checked_out_for_edit', {user_id: null, timestamp: null})

		// temporary booleans for whether the lesson is fully loaded and whether or not things are showing
		// for lesson_fully_loaded, if we already have a key_uuid, use the lesson_fully_loaded value we calculated when the lesson was first created
		if (data.key_uuid) this.lesson_fully_loaded = data.lesson_fully_loaded
		// otherwise, if data.lesson_plan is specified, the lesson has been fully loaded
		else this.lesson_fully_loaded = !empty(data.lesson_plan)
		this.lesson_showing = false
		this.item_showing = false
		this.resources_showing = false
		this.standards_showing = false
		this.student_description_showing = false
		this.activity_type = 'lesson'	// see DirectivesListItem

		// arbitrary uuid to use as the key in components; removed before saving
		this.key_uuid = U.new_uuid()

		this.lesson_plan = []
		// if we received master_data, use that for the structure of the lesson_plan
		if (master_data && master_data.lesson_plan) {
			for (let i = 0; i < master_data.lesson_plan.length; ++i) {
				// start with a shallow copy of the master's lesson_component, which should include lc_default_content (if provided in the master)
				let c = Object.assign({}, master_data.lesson_plan[i])

				// then splice in lc_parts from data if there
				if (data.lesson_plan && data.lesson_plan[i]) {
					c.lc_parts = data.lesson_plan[i].lc_parts
					c.lc_showing = data.lesson_plan[i].lc_showing	// preserve whether or not the component is showing
				} else {
					c.lc_parts = []
					// if we have lc_default_content for the master component, add it as the first part of the component
					if (!empty(c.lc_default_content)) {
						c.lc_parts.push(new Lesson_Component_Part({lcp_teacher_content:c.lc_default_content}))
					}
				}

				// create the component from this data
				this.lesson_plan.push(new Lesson_Component(c))

				// note that when we save, we'll pull out everything except lc_parts
			}

		// else we didn't receive master_data, so just use the data in data directly
		} else {
			if (!empty(data.lesson_plan)) {
				for (let c of data.lesson_plan) {
					this.lesson_plan.push(new Lesson_Component(c))
				}
			}
		}

		sdp(this, data, 'student_description', '')

		// if lesson is restricted to certain classes of users this will be set to a string that designates who's authorized
		sdp(this, data, 'restricted', '')

		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		}

		// when we first load lessons from the server, we will get a resource_ids string that specifies the order of the resources
		if (!empty(data.resource_ids) && typeof(data.resource_ids) == 'string') {
			let order = data.resource_ids.split(',')
			let arr = []
			for (let resource_id of order) {
				let r = this.resources.find(x=>x.resource_id == resource_id)
				if (r) arr.push(r)
			}
			this.resources = arr
			// we don't need the resource_ids field to be saved
		}

		this.standards = []
		if (!empty(data.standards)) {
			for (let standard of data.standards) {
				// for now at least we'll use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
				this.standards.push(new CASE_Item(standard))
			}
		}

		sdp(this, data, 'creator_user_id', 0)

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.edited_date*1)) this.edited_date = data.edited_date*1
		else this.edited_date = (empty(data.edited_date)) ? 0 : (date.parse(data.edited_date, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		delete o.key_uuid
		delete o.component
		delete o.lesson_fully_loaded
		delete o.editing
		delete o.lesson_showing
		delete o.item_showing
		delete o.resources_showing
		delete o.standards_showing
		delete o.student_description_showing
		delete o.activity_type
		delete o.checked_out_for_edit

		// remove full resources object; attach resource_ids as a comma-delimited string
		delete o.resources
		if (this.resources.length > 0) {
			o.resource_ids = ''
			for (let r of this.resources) {
				if (o.resource_ids != '') o.resource_ids += ','
				o.resource_ids += r.resource_id
			}
		}
		// if there are no resources left, have to clear the resource_ids field
		if (empty(o.resource_ids)) o.resource_ids = '*CLEAR*'

		o.lesson_plan = [];
		for (let c of this.lesson_plan) {
			o.lesson_plan.push(c.copy_for_save(flag))
		}

		// we don't want to send empty strings or 0s in to the service for certain things
		if (o.edited_date == 0) delete o.edited_date
		if (o.created_at == 0) delete o.created_at
		if (empty(o.resource_ids)) delete o.resource_ids
		return o
	}

	course_unit_header() {
		if (!this.course_code) return ''

		let lp = vapp.$store.state.all_courses.find(x=>x.course_code==this.course_code)
		if (!lp) lp = vapp.$store.state.sis_classes.find(x=>x.course_code == this.course_code)
		if (!lp) return this.course_code

		let s = lp.title

		if (this.lp_unit_id && lp.units) {
			let u = lp.units.find(x=>x.lp_unit_id == this.lp_unit_id)
			if (u && u.display_number) {
				s += ': <nobr>' + u.display_number + '</nobr>'
			}
		}

		let style = U.collection_color_style(lp)
		s = `<div class="k-lesson-course-unit-header-inner" style="border-color:${style['background-color']}; color:${style['background-color']}">${s}</div>`

		return s
	}

	date_color() {
		if (!this.lesson_date) return 'grey'
		let d = new Date(this.lesson_date + 'T00:00:00')
		// green if in the future (date is > now) and it's available to students; otherwise amber
		return (this.available_to_students() && d.getTime() > vapp.$store.state.now_date_obj.getTime()) ? 'teal' : 'amber'
	}

	date_header() {
		if (!this.lesson_date) return ''
		let d = new Date(this.lesson_date + 'T00:00:00')

		let ds = date.format(d, 'ddd M/D').replace(/(\d+\/\d+)/, '<span class="k-lesson-date-header-date">$1</span>')
		return sr('<div class="k-lesson-date-header k-lesson-date-header-$1">$2</div>', this.date_color(), ds)
	}

	// lessons cannot be assigned to different dates, but define these for convenience in places where we consider lessons and activities together (e.g. DirectivesGantt)
	due_date() { return '' }
	available_date() { return '' }
	has_multiple_available_dates() { return false }
	has_multiple_due_dates() { return false }

	standalone_link() {
		return window.location.origin + '/lesson/' + this.lesson_id
	}

	icon() { return U.directive_type_icon('lesson') }	// use the directory_type fns for this
	type_label() { return U.directive_type_label('lesson') }

	available_to_students() {
		// show lessons iff they have a date and a student_description
		return (!empty(this.lesson_date) && !empty(this.student_description))
	}

	sort_date() {
		let s = this.lesson_date ? this.lesson_date : '9999-99-99'
		// lessons are sometimes sorted along with activities, which may have available_dates. Add a placeholder available_date to the lesson sort_date value, manipulating it so that for activities and lessons with the same dates, lessons will come first
		s += ' 0000-00-00'
		// s += ' 9999-99-98'
		return s
	}

	lesson_date_passed(now) {
		if (empty(now)) now = vapp.$store.state.now_date_string
		// the lesson date is passed if it has an lesson date and the lesson date is < now
		return this.lesson_date != '' && this.lesson_date < now
	}

	// this is implemented for both activities and lessons: we consider an item "old" if it has a date and its date is < 7 days prior to now
	item_is_old() {
		return this.lesson_date != '' && this.lesson_date < vapp.$store.state.old_threshold_date_string
	}

	// return true iff the lesson is visible by the given user role; if role not specified, use state role
	// note that callers of this fn should check themselves for whether or not the user is an editor for the collection, as collection editors can always see everything
	is_visible_by_user(role) {
		// if show_all_items_when_not_signed_in is 'yes', everyone gets to see everything
		if (vapp.site_config.show_all_items_when_not_signed_in == 'yes') return true

		if (empty(role)) role = vapp.$store.getters.role

		// for now at least, it's easy: staff and admins see lessons; students and parents don't
		if (role == 'student' || role == 'parent') return false
		return true
	}
}
window.Lesson = Lesson

class Lesson_Component {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'lc_uuid', U.new_uuid())
		sdp(this, data, 'lc_title', '')
		sdp(this, data, 'lc_weight', 0)
		sdp(this, data, 'lc_guidance', '')
		sdp(this, data, 'lc_default_content', '')

		// legacy: originally we coded lc_content as a string; convert that to the first lc_part
		if (data.lc_content) {
			data.lc_parts = [{lcp_teacher_content: data.lc_content}]
		}

		// the meat of the component is one or more Lesson_Component_Parts
		this.lc_parts = []
		// if lc_parts are included in data, bring them in
		if (data.lc_parts) {
			for (let lcp of data.lc_parts) {
				this.lc_parts.push(new Lesson_Component_Part(lcp))
			}
		} else {
			// otherwise add a single blank part
			this.lc_parts.push(new Lesson_Component_Part())
		}

		sdp(this, data, 'lc_showing', false)
	}

	copy_for_save(flag) {
		let o
		if (flag == 'master') {
			// for a master, we save everything except lc_parts and lc_showing
			o = $.extend(true, {}, this)
			delete o.lc_showing

		} else {
			// otherwise all we save is lc_parts
			o = {}
		}

		o.lc_parts = [];
		for (let c of this.lc_parts) {
			o.lc_parts.push(c.copy_for_save(flag))
		}

		return o
	}

	is_empty() {
		// if there are no parts to the component, empty
		if (this.lc_parts.length == 0) return true
		for (let part of this.lc_parts) {
			// if at least one part has teacher_content, not empty
			if (!empty(part.lcp_teacher_content)) return false
		}
		// if we get here, empty
		return true
	}
}
window.Lesson_Component = Lesson_Component

class Lesson_Component_Part {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'lcp_title', '')
		sdp(this, data, 'lcp_teacher_content', '')

		sdp(this, data, 'lcp_showing', false)
		sdp(this, data, 'lcp_being_edited', false)
	}

	copy_for_save(flag) {
		let o = {}
		o.lcp_teacher_content = this.lcp_teacher_content
		o.lcp_title = this.lcp_title

		return o
	}

	has_content() {
		return !empty(this.lcp_teacher_content) || !empty(this.lcp_title)
	}
}
window.Lesson_Component_Part = Lesson_Component_Part

// note that lesson reports are stored in the db as resources
class Lesson_Report {
	constructor(data) {
		if (empty(data)) data = {}

		if (data.lesson_report_id == 'new') this.lesson_report_id = U.new_uuid()
		else if (!empty(data.lesson_report_id)) this.lesson_report_id = data.lesson_report_id
		else {
			console.warn('Lesson report created with no lesson_report_id')
			// this.lesson_report_id = U.new_uuid()
		}

		sdp(this, data, 'lesson_ids', [])
		sdp(this, data, 'collaborator_user_ids', [])
		sdp(this, data, 'reviewer_user_ids', [])
		sdp(this, data, 'owner_user_id', 0)
		// 'WLP' is used as the 'empty' value for report_title
		sdp(this, data, 'report_title', 'WLP')
		sdp(this, data, 'description', this.report_title)	// description is "shorthand" text for the report
		sdp(this, data, 'prework', {})	// indexed by user_id; values are strings
		sdp(this, data, 'feedback', {})	// indexed by user_id; values are strings
		
		this.reviews = {}	// indexed by user_id; values are {approved: true}
		if (data.reviews) for (let user_id in data.reviews) {
			let o = {}
			sdp(o, data.reviews[user_id], 'approved', false)
			this.reviews[user_id] = o
		}

		// convert dates from mysql to timestamp if necessary
		let tzo = new Date().getTimezoneOffset()*60*1000	// dates come in GMT; convert to local

		if (!isNaN(data.created_at*1)) this.created_at = data.created_at*1
		else this.created_at = (empty(data.created_at)) ? 0 : (date.parse(data.created_at, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
		
		// these are removed before saving
		sdp(this, data, 'fully_loaded', (!empty(data.owner_user_id)))	// if we didn't get an owner_user_id, we didn't fully load the report
		sdp(this, data, 'viewed', false)
	}

	copy_for_save(flag) {
		let o = $.extend(true, {}, this)

		delete o.fully_loaded
		delete o.viewed
		delete o.created_at

		return o
	}
}
window.Lesson_Report = Lesson_Report
