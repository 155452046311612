<template>
	<div>
		<v-dialog v-model="dialog_open" persistent max-width="840px">
			<v-card>
				<v-card-title class="d-flex pb-4">
					<h3>Share{{course_or_repo ? ' “My Content” from this':''}} collection with other users</h3>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="$emit('cancel_share')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
				</v-card-title>
				<v-card-text>
					<div class="d-flex">
						<v-btn color="primary" class="k-tight-btn" @click="add_user"><v-icon small class="mr-2">fas fa-plus</v-icon>Share with a new user</v-btn>
						<v-btn color="teal" v-if="show_copy_email_list_button" dark class="k-tight-btn ml-2" @click="copy_email_list"><v-icon small class="mr-2">fas fa-copy</v-icon>Copy Email List</v-btn>
						<v-spacer/>
						<div>
							<b style="font-size:16px" class="mr-2">Subscription Code:</b>
							<v-btn color="green darken-2" dark @click="copy_subscription_code_to_clipboard"><span style="font-size:18px">{{subscription_code}}</span><v-icon small class="ml-2">fas fa-copy</v-icon></v-btn>
							<v-btn class="ml-2" icon color="light-blue" @click="show_subscription_code_info"><v-icon large>fas fa-circle-info</v-icon></v-btn>
						</div>
					</div>
					<div v-if="rows.length==0" class="text-center mt-8 mb-4" style="font-size:18px"><i>Not currently shared with any other users.</i></div>
					<v-data-table v-if="rows.length>0" light :headers="headers" :items="rows" :footer-props="{itemsPerPageOptions:[10,50,100,-1]}" :items-per-page="-1" >
						<template v-slot:item="{ item }">
							<tr>
								<td>
									<v-btn icon x-small class="mr-2" @click="edit_user(item)">
										<v-icon>fas fa-edit</v-icon>
									</v-btn>
									<span>{{ item.last_name }}, {{ item.first_name }}, ({{ item.email }})</span>
								</td>
								<td class="d-flex justify-center">
									<v-icon v-if="item.has_edit_rights" small>fas fa-check</v-icon>
									<v-icon v-if="!item.has_edit_rights" small>fas fa-xmark</v-icon>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { },
	props: {
		learning_progression: { type: Object, required: true },
		course_code: { type: String, required: true },
	},
	data() {
		return {
			dialog_open: true,
			user_rights: [],
			headers: [
				{ text: 'User', align: 'left', sortable: true, value: 'user' },
				{ text: 'Collection Editor?', align: 'center', sortable: false, value: 'has_edit_rights' },
			],
			add_shared_dialog_open: false,
			edit_shared_dialog_open: false,
			user_being_edited: null,
		}
	},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		rows() {
			let arr = []
			for (let identifier in this.user_rights) {
				const user = this.user_rights[identifier]
				const o = {
					identifier: identifier,
					admin_rights_id: user.admin_rights_id,
					first_name: user.first_name,
					last_name: user.last_name,
					email: user.email,
					has_edit_rights: this.has_edit_rights(user.rights),
					rights_string: user.rights
				}
				arr.push(o)
			}
			return (arr)
		},
		course_or_repo() { return ['course','repo'].includes(this.learning_progression.collection_type) },
		subscription_code() {
			// subscription code always starts with the lp's code...
			let s = this.learning_progression.subscription_code

			// then if this is a course or repo, add the user's user_id, because for these types, subscriptions go to the user's added content for the collection
			if (this.course_or_repo) {
				s += '-' + this.user_info.user_id
			}

			return s
		},
		show_copy_email_list_button() {
			return this.user_rights.length > 0
		},
		email_list() {
			// A list of email addresses for all users who have been granted access to this collection, returned as a comma separated string
			return this.user_rights.map(r => r.email).join(', ')
		},
	},
	watch: {},
	created() { },
	mounted() {
		this.get_rights_for_collection()
		if (!this.learning_progression.subscription_code || this.learning_progression.subscription_code === '') {
			this.generate_subscription_code()
		}
	},
	methods: {
		has_edit_rights(rights) {
			// SF: collection 'rights' are prefixed with either "lp" or "view_lp"
			return rights.startsWith("view") ? false : true
		},

		get_rights_for_collection() {
			const payload = {
				user_id: this.user_info.user_id,
				course_code: this.learning_progression.course_code
			}
			U.ajax('get_rights_for_collection', payload, result => {
				this.user_rights = []
				for (let rights of result.user_rights) {
					this.user_rights.push(rights)
				}
			})
		},

		add_user() {
			this.$prompt({
				title: 'Share with new user',
				text: 'Enter the email of the user you’d like to share with:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				acceptText: `Share`,
				acceptIconAfter: 'fas fa-arrow-right',
				cancelIcon: 'fas fa-xmark',
				secondaryCheckbox: 'Grant collection edit rights to this user',
				dialogMaxWidth: 500
			}).then((rv) => {
				let grant_edit_rights = rv[1]
				let email = $.trim(rv[0]).toLowerCase().replace(/^(\S+).*/, '$1')
				this.share_collection(email, grant_edit_rights)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		share_collection(email, grant_edit_rights) {
			this.add_shared_dialog_open = false
			if (empty(email) || email.indexOf('@') == -1) {
				this.$alert('Please enter a valid user email.')
				return
			}
			for (let i = 0; i < this.user_rights.length; i++) {
				if (this.user_rights[i].email.toLowerCase() === email.toLowerCase()) {
					this.$alert('You have already shared to this email.')
					return
				}
			}
			const payload = {
				user_id: this.user_info.user_id,
				course_code: this.course_code,
				recipient_email: email,
				grant_edit_rights: grant_edit_rights
			}
			this.$store.dispatch('share_learning_progression', payload).then((result) => {
				this.$inform(sr('Collection has been shared with $1', email))
			}).catch(n => { console.log(n) }).finally(f => {
				this.$nextTick(() => {
					this.get_rights_for_collection()
				})
			});
		},

		edit_user(user) {
			this.$confirm({
				title: 'Edit user collection rights',
				text: `${user.last_name}, ${user.first_name} (${user.email})`,
				acceptText: 'Save Changes',
				acceptIcon: 'fas fa-save',
				extraBtnText: 'Cancel',
				extraBtnIcon: 'fas fa-xmark',
				extraBtnColor: 'secondary',
				cancelText: 'Remove User From Collection',
				cancelIcon: 'fas fa-trash-alt',
				cancelColor: 'red darken-2',
				secondaryCheckbox: 'Grant collection edit rights to this user',
				secondaryCheckboxInitiallyOn: user.has_edit_rights,
				dialogMaxWidth: 640,
			}).then((rv) => {
				// if rv[0] is 'extra', it means that they clicked the cancel btn
				if (rv[0] == 'extra') return

				// if we're here, save rights if they changed
				let grant_edit_rights = rv[1]
				if (grant_edit_rights != user.has_edit_rights) {
					this.save_edit(user, grant_edit_rights)
				}
				return
			}).catch(n=>{
				this.remove_collection_user(user)
			}).finally(f=>{})
		},

		save_edit(user, grant_edit_rights) {
			this.edit_shared_dialog_open = false
			const payload = {
				user_id: this.user_info.user_id,
				course_code: this.learning_progression.course_code,
				admin_rights_id: user.admin_rights_id,
				grant_edit_rights: grant_edit_rights,
				recipient_email: user.email,
				rights_string: user.rights_string
			}
			this.$store.dispatch('edit_user_shared_collection_rights', payload).then((result) => {
				this.$inform(sr('Rights for $1 updated', user.email))
			}).catch(n => console.log(n)).finally(f => {
				this.$nextTick(() => {
					this.get_rights_for_collection()
				})
			})
		},
		remove_collection_user(user) {
			this.edit_shared_dialog_open = false
			const payload = {
				user_id: this.user_info.user_id,
				admin_rights_id: user.admin_rights_id,
				recipient_email: user.email,
				rights_string: user.rights_string
			}
			this.$store.dispatch('remove_user_from_shared_collection', payload).then((result) => {
				this.$inform(sr('$1 has been removed as a shared user', user.email))
			}).catch(n => { console.log(n) }).finally(f => {
				this.$nextTick(() => {
					this.get_rights_for_collection()
				})
			});
		},

		generate_subscription_code() {
			// dispatch generate_subscription_code, which will generate a new code, save it in the db, then update the store (the updated code will then filter down to us here.)
			this.$store.dispatch('generate_subscription_code', this.learning_progression.lp_id)
		},

		copy_subscription_code_to_clipboard() {
			U.copy_to_clipboard(this.subscription_code)
			this.$inform('Subscription code copied to clipboard')
		},

		show_subscription_code_info() {
			let msg
			if (!this.course_or_repo) {
				msg = `Instead of (or in addition to) choosing users to share with here, you can also give the following “Subscription Code” to other users:<div class="my-2 text-center" style="font-size:24px"><b>${this.subscription_code}</b></div>They can then get access to the collection by clicking the “SUBSCRIBE to a Content Collection” button from the <nobr>“<i class="fas fa-cubes-stacked"></i> My Content Collections”</nobr> area and entering the Subscription Code.`
			} else {
				msg = `Instead of (or in addition to) choosing users to share with here, you can also give the following “Subscription Code” to other users:<div class="my-2 text-center" style="font-size:24px"><b>${this.subscription_code}</b></div>They can then get access to your content by clicking the “SUBSCRIBE to a Content Collection” button from the <nobr>“<i class="fas fa-cubes-stacked"></i> My Content Collections”</nobr> area and entering the Subscription Code.`
			}

			this.$confirm({
				text: msg,
				dialogMaxWidth: 600,
				acceptIcon: 'fas fa-check',
				cancelText: 'Copy Subscription Code',
				cancelIcon: 'fas fa-copy',
				cancelColor: 'green darken-2',
			}).then(y=>{}).catch(n=>{
				this.copy_subscription_code_to_clipboard()
			})
		},
		copy_email_list() {
			U.copy_to_clipboard(this.email_list)
			this.$inform('Email list copied to clipboard')
		},
	}
}
</script>

<style lang="scss">
</style>
